html {
  --section-background-color: linear-gradient(to bottom left, #11101094, #0c0818e7);
  --image-gradient: linear-gradient(to bottom left, #111010ad, #0c0a16dc);
  --imp-text-color: #36f;
}

.Blue {
  color: var(--imp-text-color) !important;
}

button:focus {
  box-shadow: none !important;
}

#no-scroll {
  height: 100vh;
  overflow: hidden;
}

::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-track {
  background: #192550;
}

::-webkit-scrollbar-thumb {
  background: #3366fff3;
  border-radius: 12px;
}

::-webkit-scrollbar-thumb:hover {
  background: #82b8ebe8;
  border-radius: 12px;
}

.sticky {
  backdrop-filter: blur(15px) !important;
  background-color: #1b1a2ea9 !important;
  transition: all .3s ease-out !important;
  box-shadow: 0 10px 10px #09051d2c !important;
}

.navbar {
  padding: .3rem 2rem !important;
  font-size: 1.2rem !important;
  transition: all .3s ease-out !important;
  position: fixed !important;
}

.navbar-toggler {
  background-color: #0000 !important;
  border-color: #0000 !important;
  position: relative !important;
}

.navbar-toggler span {
  opacity: 1 !important;
  background-color: #3366fff3 !important;
  width: 27px !important;
  height: 4px !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  display: block !important;
  left: 0 !important;
  transform: rotate(0) !important;
}

.navbar-toggler:focus, .navbar-toggler:active {
  outline: 0 !important;
}

.navbar-toggler span:first-child, .navbar-toggler span:nth-child(3) {
  transition: transform .35s ease-in-out !important;
}

.navbar-toggler:not(.collapsed) span:first-child {
  opacity: .9 !important;
  position: absolute !important;
  top: 10px !important;
  left: 12px !important;
  transform: rotate(135deg) !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
  visibility: hidden !important;
  background-color: #0000 !important;
  height: 12px !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
  opacity: .9 !important;
  position: absolute !important;
  top: 10px !important;
  left: 12px !important;
  transform: rotate(-135deg) !important;
}

@media (width <= 767px) {
  .navbar {
    background-color: #181a27 !important;
    padding: 1rem 2rem !important;
    font-size: 1.4rem !important;
  }

  .navbar-nav .nav-item a:after {
    display: none !important;
  }
}

.navbar-brand {
  color: #fafafa !important;
}

.logo {
  width: 2.5em !important;
  height: 1.4em !important;
}

.navbar-nav .nav-link {
  color: #fff !important;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.nav-link {
  padding: .8rem 1rem !important;
}

@media (width <= 767px) {
  .nav-link {
    padding: .7rem 1rem !important;
  }
}

.navbar-nav .nav-item {
  margin-left: 20px;
  position: relative;
}

.navbar-nav .nav-item a {
  z-index: 1;
  font-weight: 400;
  transition: all .3s ease-out;
  position: relative;
}

.navbar-nav .nav-item a:after {
  content: "";
  z-index: -1;
  background: #3366fff3;
  border-radius: 16px;
  width: 0;
  height: 5px;
  transition: all .3s ease-out;
  display: block;
  position: relative;
  bottom: 1px;
  left: 0;
}

.navbar-nav .nav-item a:hover:after {
  width: 100%;
}

.App {
  text-align: center;
}

.App-logo {
  pointer-events: none;
  height: 40vmin;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: 20s linear infinite App-logo-spin;
  }
}

.App-header {
  color: #fff;
  background-color: #282c34;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  font-size: calc(10px + 2vmin);
  display: flex;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.wave {
  transform-origin: 70% 70%;
  animation-name: wave-animation;
  animation-duration: 2.1s;
  animation-iteration-count: infinite;
  display: inline-block;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0);
  }

  10% {
    transform: rotate(14deg);
  }

  20% {
    transform: rotate(-8deg);
  }

  30% {
    transform: rotate(14deg);
  }

  40% {
    transform: rotate(-4deg);
  }

  50% {
    transform: rotate(10deg);
  }

  60% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(0);
  }
}

#tsparticles {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  position: fixed !important;
}

.home-header {
  padding-top: 80px !important;
}

.home-section {
  z-index: -1;
  background-image: url("stars.568f4598.png");
  background-position: top;
  background-repeat: no-repeat;
  position: relative;
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}

.home-content {
  color: #f5f5f5;
  text-align: left;
  padding: 9rem 0 2rem !important;
}

.heading {
  padding-left: 50px !important;
  font-size: 2.4em !important;
}

.heading-name {
  padding-left: 45px !important;
  font-size: 2.5em !important;
}

.main-name {
  color: #36f;
}

.Typewriter__wrapper {
  color: #36f !important;
  font-size: 2.2em !important;
  font-weight: 600 !important;
}

.Typewriter__cursor {
  color: #36f !important;
  font-size: 2.4em !important;
}

@media (width <= 767px) {
  .Typewriter__wrapper {
    font-size: 1.4em !important;
    font-weight: 500 !important;
    position: absolute !important;
  }

  .Typewriter__cursor {
    display: none !important;
  }
}

.myAvtar {
  justify-content: center !important;
  padding-top: 5em !important;
}

@media (width <= 767px) {
  .myAvtar {
    padding-top: 2em !important;
    padding-bottom: 2em !important;
  }
}

.home-about-section {
  position: relative;
  padding-top: 20px !important;
  padding-bottom: 70px !important;
}

.home-about-description {
  text-align: center;
  color: #fff !important;
  padding-top: 50px !important;
  padding-bottom: 20px !important;
}

.home-about-body {
  text-align: left;
  padding-top: 50px;
  font-size: 1.2em !important;
}

.home-about-social {
  padding-top: 25px;
  text-align: center !important;
  color: #fff !important;
}

.home-about-social-links {
  padding-top: 15px !important;
  justify-content: center !important;
  padding-inline-start: 0 !important;
  display: inline-block !important;
  position: relative !important;
}

.home-social-icons {
  text-align: center !important;
  background: #fffffff8 !important;
  border-radius: 50% !important;
  width: 40px !important;
  height: 40px !important;
  font-size: 1.2em !important;
  line-height: 2em !important;
  transition: all .5s !important;
  display: inline-block !important;
  position: relative !important;
}

.home-social-icons:before {
  content: "";
  z-index: -1;
  background: #36f;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  transition: all .5s;
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(.9);
}

.home-social-icons:hover:before {
  transform: scale(1.1);
  box-shadow: 0 0 15px #36f;
}

.home-social-icons:hover {
  color: #36f;
  text-shadow: 0 0 2px #36f;
  box-shadow: 0 0 5px #36f;
}

.social-icons {
  padding-left: 15px;
  padding-right: 15px;
  display: inline-block !important;
}

.icon-colour {
  color: #36f !important;
}

.parallax {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
}

.mountains {
  z-index: 3;
  background-image: url("mountains.481aadbc.png");
  background-position: bottom;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
}

.project-card-img {
  position: relative;
}

.project-card-img:hover:after {
  opacity: 1;
}

.project-card-icon {
  color: #fff;
  opacity: 0;
  font-size: 2em;
  transition: opacity .3s;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.project-card-img:hover .project-card-icon {
  opacity: 1;
}

#Portfolio {
  scroll-snap-align: center;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

@media (width <= 1000px) {
  #Portfolio {
    height: 50vh;
  }
}

.planets {
  z-index: 2;
  background-image: url("planets.072f25f8.png");
  background-position: bottom;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
}

.stars {
  z-index: 1;
  background-image: url("stars.568f4598.png");
  background-position: bottom;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
}

@media (width <= 767px) {
  .parallax h1 {
    text-align: center;
    font-size: 72px;
  }

  .planets {
    background-repeat: no-repeat;
    background-size: contain;
    width: 50%;
  }

  .mountains {
    background-repeat: no-repeat;
    background-size: contain;
  }
}

.footer {
  background-color: #0a0416;
  padding-top: 10px !important;
  padding-bottom: 8px !important;
  bottom: 0 !important;
}

.footer-copywright {
  text-align: center !important;
}

.footer-body {
  z-index: 1;
  text-align: center !important;
}

@media (width <= 767px) {
  .footer-copywright, .footer-body {
    text-align: center !important;
  }
}

.footer h3 {
  font-size: 1em;
  color: #fff !important;
  margin-top: .5em !important;
  margin-bottom: .5em !important;
}

.footer-icons {
  margin-top: .5em !important;
  margin-bottom: .5em !important;
  padding: 0 !important;
}

.blockquote-footer {
  color: #889ec0 !important;
}

.project-section {
  background-image: var(--section-background-color) !important;
  padding-top: 150px !important;
  padding-bottom: 30px !important;
  position: relative !important;
}

.project-card {
  height: auto !important;
  padding: 50px 25px !important;
}

.project-card-view {
  color: #fff !important;
  opacity: .9 !important;
  background-color: #0000 !important;
  height: 100% !important;
  transition: all .5s !important;
  box-shadow: 0 4px 5px 3px #35488875 !important;
}

.project-card-view:hover {
  overflow: hidden !important;
  transform: scale(1.02) !important;
  box-shadow: 0 4px 4px 5px #484a908f !important;
}

.blog-card {
  height: auto !important;
  padding: 50px 25px !important;
}

.blog-card-view {
  color: #fff !important;
  background-color: #0000 !important;
  height: 100% !important;
  transition: all .5s !important;
  box-shadow: 0 3px 3px 2px #4d4da175 !important;
}

.blog-link {
  color: #fff !important;
  text-decoration: none !important;
}

.blog-link:hover {
  cursor: pointer !important;
}

.blog-card-view:hover {
  overflow: hidden !important;
  transform: scale(1.02) !important;
  box-shadow: 0 3px 3px 5px #585eada6 !important;
}

.card-img-top {
  opacity: .8 !important;
  border-radius: 10px !important;
  padding: 20px !important;
}

.blog-img {
  opacity: .8 !important;
  border-radius: 0 !important;
  padding: 0 !important;
}

.btn-primary, .btn-primary:hover {
  color: #fff !important;
  background-color: #36f !important;
  border-color: #36f !important;
}

.btn:focus {
  box-shadow: none !important;
  outline: none !important;
}

.project-heading {
  color: #fff !important;
  padding-top: 10px !important;
  font-size: 2.3em !important;
  font-weight: 500 !important;
}

.about-section {
  background-image: var(--section-background-color) !important;
  color: #fff !important;
  padding-top: 150px !important;
  padding-bottom: 30px !important;
  position: relative !important;
}

.tech-icons {
  position: relative;
  opacity: .93 !important;
  vertical-align: middle !important;
  text-align: center !important;
  border: 1.7px solid #899de6a2 !important;
  border-radius: 20px !important;
  margin: 15px !important;
  padding: 10px !important;
  font-size: 4.5em !important;
  transition: all .4s !important;
  display: table !important;
  overflow: hidden !important;
  box-shadow: 4px 5px 4px 3px #5904a823 !important;
}

@media (width <= 767px) {
  .tech-icons {
    margin: 10px !important;
  }
}

.tech-icons:hover {
  border: 2.2px solid #7388e6e1 !important;
  overflow: hidden !important;
  transform: scale(1.05) !important;
}

.tech-icon-images {
  padding: 20px !important;
  line-height: 1.6 !important;
}

.skill {
  color: #fff;
}

@media (width <= 1000px) {
  .skill {
    padding: 10px !important;
  }

  .home-header {
    padding-top: 50px !important;
  }
}

.tech-icons:hover .skill {
  color: green;
}

.skills {
  filter: grayscale() brightness(200%);
  width: 40%;
  height: 40%;
  margin-bottom: 6px;
}

.skillsPost {
  filter: grayscale();
  width: 40%;
  height: 40%;
  margin-bottom: 6px;
}

.tech-icons:hover .skills, .tech-icons:hover .skillsPost {
  filter: none;
}

.quote-card-view {
  color: #fff !important;
  background-color: #0000 !important;
  border: none !important;
}

.about-activity {
  text-align: left !important;
  padding-left: 1px !important;
  list-style: none !important;
}

@media (width <= 767px) {
  .about-img {
    padding-top: 0 !important;
  }
}

.resume-section {
  background-image: var(--section-background-color) !important;
  color: #fff !important;
  padding-top: 110px !important;
  padding-bottom: 30px !important;
  position: relative !important;
}

.resume {
  justify-content: center;
  padding-top: 50px;
  padding-bottom: 50px;
}

.resume-left {
  padding-right: 80px !important;
}

.resume-right {
  padding-left: 80px !important;
}

@media (width <= 767px) {
  .resume-left, .resume-right {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

.resume .resume-title {
  padding-top: 30px;
  padding-bottom: 30px;
  font-size: 2em;
  font-weight: 700;
}

.resume .resume-item {
  border-left: 2px solid #8a49a8;
  margin-top: -2px;
  padding: 0 0 10px 25px;
  position: relative;
}

.resume .resume-item .resume-title {
  background: #5234795d;
  margin-bottom: 10px;
  padding: 8px 15px;
  font-size: .9em;
  font-weight: 600;
  line-height: 18px;
  display: inline-block;
}

.resume .resume-item ul {
  text-align: justify;
  padding-left: 20px;
}

.resume .resume-item ul li {
  padding-bottom: 10px;
  list-style: none;
}

.resume .resume-item:last-child {
  padding-bottom: 0;
}

.resume .resume-item:before {
  content: "";
  background: #fff;
  border: 2px solid #8a49a8;
  border-radius: 50px;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 0;
  left: -9px;
}

.like-item {
  padding-top: 10px !important;
  font-family: sans-serif !important;
  font-size: 1.1em !important;
}

.like-btn {
  background-color: #934cce5e !important;
  border-color: #934cce5e !important;
  border-radius: 5px !important;
  padding: .25rem .98rem !important;
  line-height: 1.4 !important;
  transition: all .3s !important;
}

.like-btn:hover {
  background-color: #a24dd386 !important;
  border-color: #a24dd386 !important;
  transform: translateY(-2px) !important;
}

.animate-like {
  animation-name: likeAnimation;
  animation-duration: .85s;
  animation-fill-mode: forwards;
}

@keyframes likeAnimation {
  0% {
    transform: scale(1.5);
  }

  100% {
    transform: scale(1);
  }
}

.fork-btn {
  padding-top: 10px !important;
  font-size: 1.1em !important;
}

.fork-btn-inner {
  vertical-align: middle !important;
  text-align: center !important;
  background-color: #3366ffa3 !important;
  padding: .25rem 1.1rem !important;
  line-height: 1.4em !important;
}

.fork-btn-inner:hover {
  background-color: #3366ffa3 !important;
  border-color: #3366ffa3 !important;
  transform: translateY(-2px) !important;
}

.fork-btn-inner:after {
  display: none !important;
}

#preloader {
  z-index: 999999;
  background-color: #0c0513;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.spinner {
  transform-style: preserve-3d;
  width: 44px;
  height: 44px;
  animation: 2s infinite spinner-y0fdc1;
  position: absolute;
  top: 50%;
  right: 47%;
  transform: translate(-50%, -50%);
}

.spinner > div {
  background-color: #004dff33;
  border: 2px solid #004dff;
  width: 100%;
  height: 100%;
  position: absolute;
}

.spinner div:first-of-type {
  transform: translateZ(-22px)rotateY(180deg);
}

.spinner div:nth-of-type(2) {
  transform-origin: 100% 0;
  transform: rotateY(-270deg)translateX(50%);
}

.spinner div:nth-of-type(3) {
  transform-origin: 0;
  transform: rotateY(270deg)translateX(-50%);
}

.spinner div:nth-of-type(4) {
  transform-origin: top;
  transform: rotateX(90deg)translateY(-50%);
}

.spinner div:nth-of-type(5) {
  transform-origin: bottom;
  transform: rotateX(-90deg)translateY(50%);
}

.spinner div:nth-of-type(6) {
  transform: translateZ(22px);
}

@keyframes spinner-y0fdc1 {
  0% {
    transform: rotate(45deg)rotateX(-25deg)rotateY(25deg);
  }

  50% {
    transform: rotate(45deg)rotateX(-385deg)rotateY(25deg);
  }

  100% {
    transform: rotate(45deg)rotateX(-385deg)rotateY(385deg);
  }
}

#preloader-none {
  opacity: 0;
}
/*# sourceMappingURL=index.d2b6a196.css.map */
